:root {
	--primary-pink: #f0a8c0;
	--light-pink: #fdc0ae;
	--primary-yellow: #ffb953;
	--primary-blue: #82c6c6;
	--primary-orange: #ed7749;
	--primary-white: #fcf1eb;

	--base-color: #19284c;

	--headline: #f933a4;

	/* --primary-grey: #979da6;

--primary-font: rgb(43, 43, 43);
--secondary-font: #282828;

--button-dark: #292b2c; */
}
/* App.css */

/* TEXT COLORS */
.text-pink {
	color: var(--primary-pink) !important;
}

.text-melon {
	color: var(--light-pink) !important;
}

.text-yellow {
	color: var(--primary-yellow) !important;
}

.text-blue {
	color: var(--primary-blue) !important;
}

.text-orange {
	color: var(--primary-orange) !important;
}

.text-headline {
	color: var(--headline) !important;
}

.bg-prime-white {
	background-color: var(--primary-white) !important;
}

body,
html {
	/* background-image: url(./assets/canvasBackground.jpg); */
	background-color: var(--primary-white);
	font-family: josefin-sans, sans-serif !important;
	font-weight: 300;
	font-style: normal;
	color: var(--base-color) !important;
}

.brand-signature {
	font-family: lindsey-signature, sans-serif;
	font-weight: 300;
	font-style: normal;
	color: #19284c;
}

.brand-heading {
	font-family: josefin-slab, serif;
	font-weight: 300;
	font-style: normal;
}

.brand-title {
	font-family: josefin-slab, serif;
	font-weight: 400;
	font-style: normal;
}

.brand-font {
	font-family: josefin-sans, serif;
	font-weight: 400;
	font-style: normal;
}

.body-color {
	color: var(--base-color) !important;
}

.bg-primary-white {
	background-color: var(--primary-white) !important;
}
.bg-primary-yellow {
	background-color: var(--primary-yellow) !important;
}
.bg-primary-pink {
	background-color: var(--primary-pink) !important;
}
.bg-primary-blue {
	background-color: var(--primary-blue) !important;
}
.bg-primary-orange {
	background-color: var(--primary-orange) !important;
}

.bg-mms {
	background-color: #e4f2fc !important;
}

.background-winter {
	background-image: url(../src//assets/winterBackground.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.background-fall {
	background-image: linear-gradient(
			rgba(251, 201, 101, 0.464),
			rgba(22, 9, 19, 0.333)
		),
		url(../src/assets/fallBg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}
.background-summer {
	background-image: url(../src//assets/summer.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.background-winter-summer {
	background-image: url(../src//assets/halflogo.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-spotlight {
	background-image: linear-gradient(
			rgba(215, 111, 210, 0.396),
			rgba(22, 9, 19, 0.517)
		),
		url(../src/assets/bg-spotlight.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-stage {
	background-image: linear-gradient(
			rgba(249, 49, 79, 0.396),
			rgba(117, 19, 93, 0.517)
		),
		url(../src/assets/vecteezy_theater-stage-with-red-curtains-spotlights-decor_16263043.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-music {
	background-image: linear-gradient(
			rgba(232, 212, 183, 0.181),
			rgba(232, 235, 170, 0.136)
		),
		url(../src/assets/musicNotes.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-voiceLesson {
	background-image: linear-gradient(
			rgba(232, 212, 183, 0.181),
			rgba(232, 235, 170, 0.136)
		),
		url(../src/assets/voiceLessonBG.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-clip-board {
	background-image: linear-gradient(
			rgba(229, 172, 226, 0.705),
			rgba(212, 150, 196, 0.244)
		),
		url(../src/assets/clipBoard.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-logo-white {
	background-image: linear-gradient(
			rgba(195, 142, 231, 0.822),
			rgba(210, 150, 212, 0.357)
		),
		url(../src/assets/logoBird.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-calendar {
	background-image: linear-gradient(
			rgba(211, 172, 229, 0.705),
			rgba(212, 150, 196, 0.244)
		),
		url(../src/assets/calendar.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	align-content: center;
	background-color: transparent;
}

.bg-paint-yellow {
	background-image: url('../src//assets/paint-yellow.png') !important;
	background-size: contain;
	width: 350px;
	height: 200px;
	border: 0;
	text-align: center;
	vertical-align: middle;
	align-content: center;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

/* MODAL WIDTH */
.modal-width {
	min-width: 70% !important;
}

/* -----------------------------HOVER EFFECT------------------------------ */
.click:hover {
	transform: scale(2); /* Equal to scaleX(2) scaleY(2) */
}

/* unvisited link */
a {
	/* color: var(--primary-grey); */
	text-decoration: none;
}

.content {
	min-height: calc(100vh - 40px);
}

/* visited link */
a:visited {
	color: none;
}

/* mouse over link */
a:hover {
	color: var(--logo-red);
}

/* ======================================ACCORDION================= */

.accordion-button {
	background-color: none !important;
	color: var(--base-color) !important;
}

.accordion-button:focus {
	box-shadow: none;
}

/* .accordion-button:hover {
	filter: drop-shadow(0.16rem 0.1rem 0.3rem #19284c);
} */

.accordion-button:not(.collapsed) {
	color: white !important;
	background-color: var(--primary-blue);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

/* .accordion-button::after {
	background-image: url(../src/assets/birdIconLarge.png);
} */

.accordion-button:not(.collapsed)::after {
	color: white;
	background-image: url(../src/assets/buttonLOGO.png);
}
.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: '';
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}
/* =======================================================BUTTONS===================================================== */

.video {
	aspect-ratio: 16 / 9;
	width: 100%;
}

/* PAINT BUTTON */

button,
.btn {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.paint-button {
	width: 350px;
	height: 200px;
	border: 0;
	text-align: center;
	color: white;
	/* font-size: 2rem; */
	background-image: url('../src//assets/paint-blue.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

.paint-button:hover {
	width: 350px;
	height: 200px;
	border: 0;
	cursor: pointer;
	text-align: center;
	color: var(--primary-pink);

	/* font-size: 2rem; */
	background-image: url('../src//assets/LOGOicon.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

.paint-button-small {
	width: 200px;
	height: 100px;
	border: 0;
	text-align: center;
	color: white;

	/* font-size: 2rem; */
	background-image: url('../src//assets/paint-orange.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

.paint-button-small:hover {
	width: 200px;
	height: 100;
	border: 0;
	cursor: pointer;
	text-align: center;
	color: var(--primary-pink);
	/* font-size: 2rem; */
	background-image: url('../src//assets/LOGOicon.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

.paint-button-small-blue {
	width: 200px;
	height: 100px;
	border: 0;
	text-align: center;
	color: white;

	/* font-size: 2rem; */
	background-image: url('../src//assets/paint-blue.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

.paint-button-small-blue:hover {
	width: 200px;
	height: 100;
	border: 0;
	cursor: pointer;
	text-align: center;
	color: var(--primary-pink);
	/* font-size: 2rem; */
	background-image: url('../src//assets/LOGOicon.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
}

/* BASE COLOR */

.btn-primary {
	color: white !important;
	background-color: #82c6c6 !important;
	/* background-image: url(../src/assets/paint-base.png); */
	border-color: none !important;
	font-family: josefin-sans, serif;

	font-style: normal;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	color: black !important;
	background-color: transparent !important;
	border-color: black !important;
}
/* ---outline  */
.btn-outline-primary {
	color: none !important;
	background-color: none !important;
	border-color: none !important;
	font-family: josefin-sans, serif;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-primary {
	color: white !important;
	background-color: var(--primary-blue) !important;
	border-color: none !important;
	font-family: josefin-sans, serif;
}

/* LIGHT PINK*/

.btn-secondary {
	color: white !important;

	background-color: #f933a4 !important;
	border-color: black !important;
	font-family: josefin-sans, serif;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
	color: black !important;
	background-color: transparent !important;
	border-color: black !important;
	font-family: josefin-sans, serif;
}

/* GREEN */

.btn-success {
	color: var(--primary-white) !important;
	background-color: var(--primary-green) !important;
	border-color: var(--button-dark) !important; /*set the color you want here*/
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	color: var(--primary-white) !important;
	background-color: rgb(28, 197, 28) !important;
	border-color: rgb(66, 66, 66) !important;
	transform: scale(2);
}

/* RED */

.btn-danger {
	color: var(--primary-white) !important;
	background-color: var(--primary-pink) !important;
	border-color: black !important; /*set the color you want here*/
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	color: black !important;
	background-color: transparent !important;
	border-color: black !important;
}

/* YELLOW */

.btn-warning {
	color: var(--primary-white) !important;
	background-color: #5629cd !important;
	/* border-color: grey !important;  */
	font-family: josefin-sans, serif;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	color: black !important;
	background-color: transparent !important;
	border-color: black !important;
}

.btn-outline-warning {
	color: #5629cd !important;
	background-color: transparent !important;
	border-color: #5629cd !important;
	font-family: josefin-sans, serif;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.open > .dropdown-toggle.btn-outline-warning {
	color: black !important;
	background-image: linear-gradient(
		to right,
		#2579e1,
		#2405f2,
		#0460fe,
		#2405f2
	);
	background-position: 100% 0;
	moz-transition: all 0.4s ease-in-out;
	font-family: josefin-sans, serif;

	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	box-shadow: 0 4px 8px 0 rgba(65, 132, 234, 0.75) !important;
	background-size: 200% 200%;
	border-color: black !important;
}

/* ORANGE */

.btn-info {
	color: var(--primary-white) !important;
	background-color: var(--primary-orange) !important;
	border-color: black !important; /*set the color you want here*/
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	color: var(--base-color) !important;
	background-color: transparent !important;
	border-color: rgb(66, 66, 66) !important;
}

.mb-n1 {
	margin-bottom: -1.75rem !important;
}

/* selected link */
a:active {
	color: var(--primary-blue);
}

.border-color {
	background-color: var(--secondary-white);
}

.signature {
	font-family: adobe-handwriting-ernie, sans-serif;
	font-weight: 300;
	font-style: normal;
	color: #19284c !important;
}

.fa-music {
	color: black;
}

/* ------------------------------ALERT */

.alert {
	--bs-alert-bg: transparent;
	--bs-alert-padding-x: 1rem;
	--bs-alert-padding-y: 1rem;
	--bs-alert-margin-bottom: 1rem;
	--bs-alert-color: inherit;
	--bs-alert-border-color: transparent;
	--bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
	--bs-alert-border-radius: var(--bs-border-radius);
	--bs-alert-link-color: inherit;
	position: relative;
	padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
	margin-bottom: var(--bs-alert-margin-bottom);
	color: var(--bs-alert-color);
	background-color: var(--bs-alert-bg);
	border: var(--bs-alert-border);
	border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
	color: var(--bs-alert-link-color);
}

.alert-dismissible {
	padding-right: 3rem;
}
.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

.alert-primary {
	--bs-alert-color: white;
	--bs-alert-bg: var(--primary-blue) !important;
	--bs-alert-border-color: ;
}

.alert-secondary {
	--bs-alert-color: white;
	--bs-alert-bg: #c0bdf3 !important;
	--bs-alert-border-color: var(--bs-secondary-border-subtle);
	--bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
	--bs-alert-color: var(--bs-success-text-emphasis);
	--bs-alert-bg: var(--bs-success-bg-subtle);
	--bs-alert-border-color: var(--bs-success-border-subtle);
	--bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
	--bs-alert-color: var(--bs-info-text-emphasis);
	--bs-alert-bg: var(--bs-info-bg-subtle);
	--bs-alert-border-color: var(--bs-info-border-subtle);
	--bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
	--bs-alert-color: white;
	--bs-alert-bg: var(--primary-yellow);
	--bs-alert-border-color: ORANGE;
	--bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-danger {
	--bs-alert-color: white;

	--bs-alert-bg: var(--primary-pink) !important;
	--bs-alert-border-color: var(--bs-danger-border-subtle);
	--bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
	--bs-alert-color: var(--bs-light-text-emphasis);
	--bs-alert-bg: var(--bs-light-bg-subtle);
	--bs-alert-border-color: var(--bs-light-border-subtle);
	--bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
	--bs-alert-color: var(--bs-dark-text-emphasis);
	--bs-alert-bg: var(--bs-dark-bg-subtle);
	--bs-alert-border-color: var(--bs-dark-border-subtle);
	--bs-alert-link-color: var(--bs-dark-text-emphasis);
}

/* ----------------------------------------------------------------DROP-SHADOW------------------ */
.drop-shadow {
	filter: drop-shadow(0.04rem 0.02rem 0.02rem #19284c);
}
.drop-shadow-white {
	filter: drop-shadow(0.04rem 0.02rem 0.02rem #e4e4e4);
}
.drop-shadow-hero {
	filter: drop-shadow(0.16rem 0.2rem 0.2rem #19284c);
}
.drop-shadow-blur {
	filter: drop-shadow(0.08rem 0.15rem 0.2rem #19284c);
}
.drop-shadow-thin {
	filter: drop-shadow(0.05rem 0.04rem 0.02rem #19284c);
}

.drop-shadow-light {
	filter: drop-shadow(0.08rem 0.05rem 0.01rem #adadad);
}

.stroke {
	-webkit-text-stroke: 0.03px rgb(78, 77, 77);
}

.stroke-button {
	-webkit-text-stroke: 0.7px rgb(31, 31, 31);
}

.stroke-dark {
	-webkit-text-stroke: 0.05px rgb(31, 31, 31);
}

.stroke-icon {
	-webkit-text-stroke: 1px black;
	text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
		-1px 1px 0 #000, 1px 1px 0 #000;
}

.drop-shadow-head {
	filter: drop-shadow(0.06rem 0.05rem 0.04rem #000000);
}

.drop-shadow-title {
	filter: drop-shadow(0.1rem 0.07rem 0.05rem #000000);
}
/* ----HERO----------------------------------------------------------- */

a.nav-link-headline {
	/* padding: 0; */
	color: var(--headline);
	size: 1.12rem;
}

a.nav-link {
	/* padding: 0; */
	color: var(--primary-grey);
	size: 1.12rem;
}

a.nav-link-none {
	/* padding: 0; */
	text-decoration: none;
}

/* ----footer---- */

p.footer-nav,
a.footer-nav {
	color: var(--primary-grey);
	/* filter: drop-shadow(0.07rem 0.05rem 0.020rem #bababa); */
}

.up-arrow {
	color: var(--button-dark) !important;
	opacity: 50% !important;
	filter: drop-shadow(0.12rem 0.14rem 0.05rem #969696);
}

/* ----------------------------------------------------------------SWIPER-------------- */
.swiper {
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
}

.swiper-slide {
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
}

.swiper-slide img {
	display: block;
	width: 100%;
}

#swiper-color .swiper-button-next {
	color: #86898b;
}

#swiper-color .swiper-button-prev {
	color: #86898b;
}

#swiper-color .swiper-pagination-bullet-active {
	opacity: 1;
	background: #86898b;
}

#swiper-container .swiper-container {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	min-height: 0;
	min-width: 0;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0 0 0 0;
	z-index: 1;
}

#swiper-color .swiper-wrapper {
	position: relative;
	z-index: 1;
	display: flex;
	max-width: 100%;
	max-height: 100%;
	min-height: 0;
	min-width: 0;
	transition-property: transform;
	box-sizing: content-box;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}

#swiper-color .swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
}

/* ------------------------------------------------------------------FONTS------------- */

@font-face {
	font-family: 'Breathing';
	src: local('Breathing'),
		url('../src/fonts/Breathing\ Font/Breathing.ttf') format('truetype');
}

.breathing-font {
	font-family: 'Breathing';
}
