* {
	box-sizing: border-box;
}

/* body {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
} */

.square {
	width: 15rem;
	height: 8rem;
	cursor: pointer;
	background-image: linear-gradient(
		45deg,
		rgb(121, 113, 234),
		rgb(97, 71, 182)
	);
}

.full-screen-square {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 0;
	background-image: linear-gradient(45deg, #da3a3a, #f68a1e);
}
