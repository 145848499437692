.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button {
		transition: none;
	}
}
.accordion-button-blue:not(.collapsed) {
	color: whitesmoke;
	background-color: var(--primary-blue) !important;
	opacity: 0.85;

	/* box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.17); */
}
.accordion-button:not(.collapsed)::after {
	background-image: url(../../assets/buttonLOGO.png);
	transform: rotate(-180deg);
}
.accordion-button::after {
	flex-shrink: 0;
	width: 2rem;
	height: 2rem;
	margin-left: auto;
	content: '';
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 2rem;
	transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button::after {
		transition: none;
	}
}
.accordion-button:hover {
	z-index: 2;
}
.accordion-button:focus {
	z-index: 3;
	border-color: #000000;
	outline: 0;
	/* box-shadow: 0 0 0 0.25rem #8e24306c; */
}
